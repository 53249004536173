html,
body,
#root,
.App,
.dashboard-layout,
.dashboard-layout-content,
.ant-layout,
.ant-tabs-nav,
.professor-course-content .ant-page-header {
    height: 100%;
}

.ant-page-header-content {
    height: calc(100% - 125px)
}

.professor-course-content .ant-layout-content {
    height: calc(100% - 250px);
}

.professor-course-content .ant-tabs-content-holder {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 85px);
}

.professor-course-content .ant-tabs-nav {
    height: calc(100% - 80px);
}

.professor-course-content .ant-tabs-tab-btn {
    width: 100%
}

.ant-layout-content.professor-course-content {
    height: calc(100% - 10px);
    overflow: hidden;
}

.dashboard-layout-content:has(.professor-course-content) {
    height: calc(100% - 10px);
}

.dashboard-layout .ant-layout-content:has(.professor-course-content) {
    overflow: hidden;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    text-align: center;
}

#root {
    overflow: hidden;
}